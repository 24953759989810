*{
  box-sizing: border-box;
  margin:0;
  
}
body{
  /* background-color: #F9F9F9; */
  background-color: #ECF2FF !important;

}


