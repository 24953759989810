.quiz-container{
    display: flex;
    flex-direction: column;
}
.columns{
    display: flex;
    flex-direction: row;
    height:100vh;
}
/* .header{
    height: 2.5rem;
    width:100%;
    background-color: #5D3891;
    display: flex;
    justify-content: flex-end;
    color: white;
    font-size:1.8rem;
} */

.qbtn{
    display: flex;
    flex-wrap: wrap;
}
.timer{
    margin-right:7rem;
    color:white !important;
    font-size: 1.5rem;
}
.nav-quiz{
    flex-grow: 1;
    background-color: #F5F5F5;
    border: 0.1rem solid grey;
    width: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.quiz-window{
    flex-grow: 4;
    background-color: #ECF9FF;
    height:100vh;
}

.review .save{
    height:0.2rem;
    width:1rem; 
}
.to-review{
    
    color: #E7B10A !important;
}
.answered{
    
    color: green !important;
}
.current{
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    margin:0.8rem;
    border-color: green !important;
}
.quiz{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.instruction{
    display: flex;
    /* background-color:#FFFBF5 ; */
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}