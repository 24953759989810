.home{
  width:100%;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.chips{
  /* display:flex;
  flex-direction: column; */
  width:auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.results{
  width:100%;
  margin-left: auto;
  margin-right: auto;
}
.responses{
  text-align: justify;

}

/* @media (min-width: 768px) and (max-width: 1024px) {
  .results{
    width: 800px;
    margin-left: auto;
    margin-right: auto;
   
  }
}
@media (min-width: 325px) and (max-width: 768px) {
  .results{
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
   
  }
} */